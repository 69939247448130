import React from 'react';
import PropTypes from 'prop-types';
import UploadImage from './UploadImage';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import getUploadInfo from '../../../utils/getUploadInfo';

EventImages.propTypes = {
  event: PropTypes.object.isRequired
};

export default function EventImages(props) {
  const { event } = props;

  const uploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          'evaluationEventEducator.eventId': event.id
          // 'evaluationEventEducator.releasePermission': true
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'id asc'
    }
  });

  const _uploads = uploads.data.filter(function(upload) {
    const info = getUploadInfo(upload.data.name);
    return info.isImage;
  });

  if (_uploads.length > 0) {
    return (
      <div className="px-4 pb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {_uploads.map(function(upload, index) {
            return (
              <UploadImage
                key={index}
                upload={upload}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return null;
}
