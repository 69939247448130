/**
 * This component serves as the root of your application, and should typically be the only
 * component subscribed to the store.
 *
 * It is also a good place to fetch the current user. Once you have configured 'models/currentUser'
 * to fetch the current user (by pointing it to the correct API endpoint) uncomment the commented
 * out code below in order to fetch the user, display a loading experience while they're being
 * fetched, and store the user in the applications context so that components can retrieve it
 * without having to pass it down through props or extract it from the Redux store directly.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import PayloadStates from '../constants/PayloadStates';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import { UserContext } from '@lore/auth';
import { useConfig } from '@lore/config';
import { DialogProvider } from '@lore/dialogs';
import { Navigate, Outlet } from 'react-router-dom';
import storage from '../utils/storage';
import isBlacklistedRedirectUrl from '../utils/isBlacklistedRedirectUrl';
import NetworkContext from '../context/NetworkContext';
import NetworkStewardContext from '../context/NetworkStewardContext';
import useTags from '../hooks/useTags';
import useTagTrees from '../hooks/useTagTrees';
import useRouter from '../hooks/useRouter';
import DialogLauncherLayout from '../../hooks/@lore/dialogs-routable/_launcher/Layout';
import _ from 'lodash';
import { parse } from 'query-string';
import { extractArrayFromQuery } from '../utils/query';
import Redirect from '../components/Redirect';
import EnforceNetwork from './EnforceNetwork';
import GoogleUser from './GoogleUser';

export default function Master(props) {
  const { location } = useRouter();
  const config = useConfig();

  const user = useConnect('currentUser.singleton');

  const tags = useTags(undefined, {
    enabled: !!user.id
  });

  const tagTrees = useTagTrees(undefined, {
    enabled: !!user.id
  });

  const networks = useConnect('network.find', {
    pagination: {
      pageSize: 1000,
      order: 'name desc'
    }
  }, {
    enabled: !!user.id
  });

  const networkStewards = useConnect('networkSteward.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  }, {
    enabled: !!user.id
  });

  if (user.state === PayloadStates.FETCHING) {
    return null;
  }

  if (user.state === PayloadStates.ERROR_FETCHING) {
    const redirectUrl = `${window.location.pathname}${window.location.search}`;

    if (!isBlacklistedRedirectUrl(redirectUrl)) {
      storage.set('redirectUrl', redirectUrl);
    }

    if (user.error && user.error.statusCode === 401) {
      return (
        <Redirect to="/logout" />
      );
    }

    return (
      <Redirect to="/unauthorized" />
    );
  }

  if (
    tags.state === PayloadStates.FETCHING ||
    tagTrees.state === PayloadStates.FETCHING ||
    networks.state === PayloadStates.FETCHING ||
    networkStewards.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  // function getNetwork() {
  //   const tokens = location.pathname.split('/');
  //   return _.find(networks.data, function(network) {
  //     return network.data.subdomain === tokens[2];
  //   });
  // }

  function getNetwork() {
    const networksArray = extractArrayFromQuery(parse(location.search), 'networks');
    return _.find(networks.data, function(network) {
      return network.id === networksArray[0];
    });
  }

  const network = getNetwork();

  let networkSteward = network ?
    _.find(networkStewards.data, m => m.data.networkId === network.id) :
    undefined;

  if (
    networkStewards.data.length < 1 &&
    !user.data.isStaff
  ) {
    return (
      <Redirect to="/unauthorized" />
    );
  }

  return (
    <UserContext.Provider value={user}>
      <NetworkContext.Provider value={network}>
        <NetworkStewardContext.Provider value={networkSteward}>
          <RemoveLoadingScreen />
          <GoogleUser />
          <EnforceNetwork networkStewards={networkStewards} />
          <DialogProvider
            templates={config.dialogs.templates}
            defaultTemplate={config.dialogs.defaultTemplate}
          >
            <Outlet />
          </DialogProvider>
          <DialogLauncherLayout/>
        </NetworkStewardContext.Provider>
      </NetworkContext.Provider>
    </UserContext.Provider>
  );
};
