import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../_common_old/FormTemplate.v2';
import Avatar from '../../components/Avatar';
import FormSection from '../../forms/_common/FormSection';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import FormField from '../../forms/_common/FormField';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    user,
    creator,
    emailPreview
  } = props;

  const promptMap = {
    // 0: `Great! What's your question?`,
    1: `Awesome! Many Partners are happy to offer their thoughts or feedback. Try to be as specific as possible to help them give you the feedback you need.`,
    2: `Exciting! Partners are much more likely to respond when they understand your need. Let's make it easy for them to say "yes"!`,
    3: `Networking is a great way to build relationships! Why do you think this partner might have the right connections for you?`
  };

  return (
    <FormTemplate
      gray={true}
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={user.data.avatarUrl} />
        </div>
      )}
      title={(
        <>
          Ready to connect {creator.data.firstName} and {user.data.firstName}?
        </>
      )}
      subtitle={(
        <>
          We'll email the project details to {user.data.firstName} and ask them to
          reply to {creator.data.firstName} directly. When educators log into the
          platform, they can see and message partners first.
        </>
      )}
      onClose={onClose}
      showClose={false}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            title="Customize your message"
            subtitle={(
              <>
                We've drafted a default message for you - feel free to make it your own
                or use it as is. You can preview the message below.
              </>
            )}
          >
            <FormField name="customMessage" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <TextAreaField
                  //label="Description*"
                  // placeholder="Custom message..."
                  rows={6}
                  value={field.value}
                  error={field.error}
                  onChange={field.onChange}
                />
              )}
            </FormField>
          </FormSection>
          <FormSection
            // title="This is what we'll send to the partner."
            subtitle={`This is a preview of what we'll send to ${user.data.firstName}. You can make changes above.`}
          >
            <div className="bg-white border border-gray-300 shadow rounded-lg h-96 overflow-auto">
              {emailPreview}
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.reason} onClick={onSubmit}>
            Send invite
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
