import React from 'react';
import getUploadInfo from '../../../utils/getUploadInfo';
import classNames from 'classnames';
import getUrlForUpload from '../../../utils/getUrlForUpload';

export default function Photograph(props) {
  const { evaluationUpload } = props;

  const url = getUrlForUpload(evaluationUpload);
  const info = getUploadInfo(evaluationUpload.data.name);

  return (
    <div>
      <div className="space-y-4">
        {info.isImage ? (
          <div className="aspect-w-3 aspect-h-2">
            <img
              className="object-cover shadow-cs-flat rounded-lg"
              src={evaluationUpload.data.url}
              alt=""
            />
          </div>
        ) : (
          <div className={classNames(
            'aspect-w-3 aspect-h-2',
            'bg-cs-gray-500 rounded-lg overflow-hidden',
            'shadow-cs-flat -m-px border border-cs-gray-100'
          )}>
            {/*<img className="object-cover shadow-lg rounded-lg" src={url} alt="" />*/}
            <div className="flex flex-col items-center justify-center h-full">
              <info.icon.hack className="h-20 w-20 text-white"/>
              <div className="text-white">
                {info.extension ? `.${info.extension}` : '[filetype unknown]'}
              </div>
            </div>
          </div>
        )}
        <div className="space-y-2">
          <div className="text-md leading-6 font-medium space-y-1">
            <a
              className="text-accent hover:text-primary underline"
              href={url}
              target="_blank"
            >
              {evaluationUpload.data.name}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
