import { parse, stringify } from 'query-string';
import useStewardshipFilter from './useStewardshipFilter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, extra = {} } = props;

  const stewardshipFilter = useStewardshipFilter();

  return useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfJobs',
            relation: 'jobs',
            $where: {
              isEducator: { $isEducator: true },
              // siteId: {
              //   $exists: false
              // },
              $or: stewardshipFilter
            }
          }
        ],
        $where: {
          // isEducator: { $isEducator: true },
          // siteId: {
          //   $exists: false
          // },
          // $or: stewardshipFilter,
          numberOfJobs: {
            $gt: 0
          },
          ...extra
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  }, {
    keepPreviousData: true
  });
}
