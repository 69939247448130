import React from 'react';
import Section from '../../../pages/_components/sidePanel/Section.new';
import TextContentSection from '../../../components_profiles/TextContentSection';
import ContentCard from '../../../components_profiles/ContentCard';
import Category from '../../../pages/user/User/Category';
import Entry from '../../../pages/user/User/Entry';

export default function StudentReflections(props) {
  const { evaluation } = props;

  return (
    <ContentCard className="space-y-4">
      <Category>
        Student Reflections
        <div className="text-base font-normal text-cs-gray-500">
          Please rate any changes you observed with your learner(s) as a result of the interaction.
        </div>
      </Category>
      <div className="space-y-3">
        <Entry
          title="Learner(s) were more engaged."
          description={evaluation.data.engagedRating || '-'}
        />
        <Entry
          title="Learner(s) built social-emotional skills."
          description={evaluation.data.skillsRating || '-'}
        />
        <Entry
          title="Learner(s) increased awareness of future academic and career opportunities."
          description={evaluation.data.awarenessRating || '-'}
        />
        {/*<Entry*/}
        {/*  title="Learner(s) increased understanding of the real-world application of topic/content."*/}
        {/*  description={evaluation.data.understandingRating || '-'}*/}
        {/*/>*/}
        <Entry
          title="Learner(s) practiced critical thinking, problem-solving, and/or working as a team."
          description={evaluation.data.practicedRating || '-'}
        />
      </div>
    </ContentCard>
  );
}
