import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import SectionTitle from '../_common/SectionTitle';
import Students from '../evaluation_project_educator/sections/Students';
import StudentReflections from '../evaluation_project_educator/sections/StudentReflections';
import PersonalReflections from '../evaluation_project_educator/sections/PersonalReflections';
import Outcome from '../evaluation_project_educator/sections/Outcome';
import Photographs from './sections/Photographs';
import Testimonials from './sections/Testimonials';

Template.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Template(props) {
  const { project, evaluationProjectEducator } = props;

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const page = `Project #${project.id}`;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          Project Story: {project.data.title ? `${project.data.title}: ${page}` : page}
        </title>
      </Helmet>
      <Header
        project={project}
        evaluationProjectEducator={evaluationProjectEducator}
      />
      <div className="space-y-12">
        <SectionTitle
          title="Testimonials"
          subtitle="Provided by the people involved in this project as part of their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Testimonials project={project} />
          </div>
        </SectionTitle>
        <SectionTitle
          title="Photos"
          subtitle="Uploaded by the educator during their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Photographs evaluation={evaluationProjectEducator} />
          </div>
        </SectionTitle>
        <SectionTitle
          title="Impact"
          subtitle="What the educator said about the experience during their evaluation."
        >
          <div className="grid grid-cols-1 gap-4">
            <Students evaluation={evaluationProjectEducator} />
            <StudentReflections evaluation={evaluationProjectEducator} />
            <PersonalReflections evaluation={evaluationProjectEducator} />
            <Outcome evaluation={evaluationProjectEducator} />
          </div>
        </SectionTitle>
      </div>
    </div>
  );
};
