import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import educator from './educator';
import recommendPartners from './recommendPartners';
import school from './school';
import statuses from './statuses';
import testimonials from './testimonials';
import title from './title';
import trainings from './trainings';
import ratings from './ratings';
import activities from './activities';

function eventTag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'evaluationEventEducatorEventTagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  educator,
  // evalExperiences,
  // partner,
  recommendPartners,
  school,
  statuses,
  testimonials,
  title,
  trainings,

  engagedRatings: ratings('engagedRating'),
  skillsRatings: ratings('skillsRating'),
  awarenessRatings: ratings('awarenessRating'),
  practicedRatings: ratings('practicedRating'),

  expandedRatings: ratings('expandedRating'),
  confidenceRatings: ratings('confidenceRating'),
  supportedRatings: ratings('supportedRating'),
  excitementRatings: ratings('excitementRating'),

  activities,
  ages: eventTag(),
  commitments: eventTag(),
  // days: eventTag(),
  expertiseTypes: eventTag(),
  groups: eventTag(),
  guidances: eventTag(),
  interactionTypes: eventTag(),
  // participants: eventTag(),
  subjects: eventTag(),
  // times: eventTag(),
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
