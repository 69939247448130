import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Section from '../../_components/sidePanel/Section';
import TextField from '../../_components/sidePanel/TextField';
import Photograph from './Photograph';

export default function Photographs(props) {
  const { evaluation, hideIfMissing = false } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          evaluationProjectEducatorId: evaluation.id
        }
      }
    }
  });

  if (hideIfMissing && evaluationUploads.data.length === 0) {
    return null;
  }

  return (
    <Section
      title="Project Photographs"
      // subtitle="Documents uploaded by the educator with more information about the evaluation."
    >
      {/*<TextField label="Do you have permission to share these images?">*/}
      {/*  {evaluation.data.releasePermission ? 'Yes' : 'No'}*/}
      {/*</TextField>*/}
      <div>
        <div className="grid grid-cols-4 gap-4">
          {evaluationUploads.data.map(function(evaluationUpload) {
            return (
              <Photograph
                key={evaluationUpload.id}
                evaluationUpload={evaluationUpload}
              />
            );
          })}
        </div>
      </div>
      {evaluationUploads.data.length === 0 && '-'}
    </Section>
  )
}
