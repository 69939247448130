import React from 'react';
import { AcademicCapIcon, UsersIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';

export default function Partners(props) {
  const { site } = props;

  const radius = 50;

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          id: {
            $location: {
              radius: radius,
              latitude: site.data.latitude,
              longitude: site.data.longitude
            }
          },
          isEducator: { $isEducator: false }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  // const userSites = useConnect('userSite.find', {
  //   where: {
  //     eager: {
  //       $where: {
  //         siteId: site.id
  //       }
  //     }
  //   },
  //   pagination: {
  //     pageSize: 1
  //   }
  // });

  const usersCount = users.state === PayloadStates.FETCHING ? '...' : users.meta.totalCount;
  // const userSitesCount = userSites.state === PayloadStates.FETCHING ? '...' : userSites.meta.totalCount;

  // if (
  //   site.id === 102217 &&
  //   users.state === PayloadStates.RESOLVED
  // ) {
  //   _.sortBy(users.data, 'id').forEach((user) => {
  //     console.log(user.id);
  //   });
  // }

  return (
    <IconSection
      icon={UsersIcon}
      tooltip={`Partners within ${radius} miles`}
      title={usersCount}
      // title={`${usersCount} / ${userSitesCount}`}
    />
  );
}
