import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../partners/useStewardshipFilter';
import getQueryUtils, { attributeKeys } from '../partners/queryUtils';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 4,
    returnRawQuery = false
  } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const queryUtils = getQueryUtils(search, attributeKeys, timestamp);

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'sum',
            alias: 'numberOfHoursServed',
            field: 'hours',
            relation: 'partnerProjectEvaluations'
          },
          {
            type: 'sum',
            alias: 'totalOfContributionsProvided',
            field: 'contributionValue',
            relation: 'partnerProjectEvaluations'
          },
          {
            type: 'count',
            alias: 'numberOfQuestionsConnect',
            relation: 'questions',
            $where: {
              response: 'Connect'
            }
          },
          {
            type: 'count',
            alias: 'numberOfQuestionsPending',
            relation: 'questions',
            $where: {
              response: 'Pending'
            }
          },
          {
            type: 'count',
            alias: 'numberOfQuestionsDecline',
            relation: 'questions',
            $where: {
              response: 'Decline'
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsYes',
            relation: 'invitations',
            $where: {
              response: 'Yes'
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsMaybe',
            relation: 'invitations',
            $where: {
              response: 'Maybe'
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsPending',
            relation: 'invitations',
            $where: {
              response: 'Pending'
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsNo',
            relation: 'invitations',
            $where: {
              response: 'No'
            }
          },
          {
            type: 'count',
            alias: 'numberOfOffersCreated',
            relation: 'offers'
          }
        ],
        $where: {
          isEducator: { $isEducator: false },
          $and: [...queryUtils.where],
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return result;
}
