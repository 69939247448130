import React from 'react';
import { parse, stringify } from 'query-string';
import { MISSING_PARTNER_FIELDS } from '../../../utils/user/MISSING_FIELDS';
import useStewardshipFilter from '../../partners/useStewardshipFilter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 12, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'sum',
            alias: 'numberOfHoursServed',
            relation: 'partnerProjectEvaluations',
            field: 'hours'
          }
        ],
        $where: {
          isEducator: { $isEducator: false },
          numberOfHoursServed: {
            $gte: 25
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'numberOfHoursServed desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return result;
}
