import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../_common_old/FormTemplate.v2';
import Avatar from '../../components/Avatar';
import FormSection from '../../forms/_common/FormSection';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    user,
    creator,
    emailPreview
  } = props;

  const promptMap = {
    // 0: `Great! What's your question?`,
    1: `Awesome! Many Educators are happy to offer their thoughts or feedback. Since most of their time is working directly with students, you'll be most successful if you are as specific as possible about what feedback you need.`,
    2: `Exciting! Educators are much more likely to respond when they clearly understand what you're offering. Let's make it easy for them to say "yes"!`
  };

  return (
    <FormTemplate
      gray={true}
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={user.data.avatarUrl} />
        </div>
      )}
      title={(
        <>
          Ready to connect {creator.data.firstName} and {user.data.firstName}?
        </>
      )}
      subtitle={(
        <>
          We'll email the offer details to {user.data.firstName} and ask them to
          reply to {creator.data.firstName} directly. When partners log into the
          platform, they can see and message educators first.
        </>
      )}
      onClose={onClose}
      showClose={false}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            title={`This is a preview of what we'll send to ${user.data.firstName}.`}
          >
            <div className="bg-white border border-gray-300 shadow rounded-lg h-96 overflow-auto">
              {emailPreview}
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.reason} onClick={onSubmit}>
            Send invite
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
